import { BottomNavigation, BottomNavigationAction, InputLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import Header from './Header'
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { getlocationlistmapId, sendMessage } from '../API';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Details() {
    
const[btndisable, setbtndisable] =useState(false)
    const dispatch =useDispatch()
    const otpSuccess = ()=> toast.success('OTP send Successfully')
    const otpError = ()=> toast.error('OTP send Failed')
    const Data = useLocation()
    const cartData=Data.state.cartdata
    const locationName=Data.state.locationName
    const navigate = useNavigate()
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = yup.object({
        contactno :yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
    })  
    const formik = useFormik({
        initialValues: {
           contactno:'',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

        let message= Math.floor(100000 + Math.random() * 900000)
        sendMessage(values.contactno,message)
        .then(res=>{
          console.log('OTP Send Success' ,res)
           if(res.data.status===200 ||res.data.status==="200" ){
            let contactNo = values.contactno
            otpSuccess()
            navigate('/cart', {state:{cartData,message,locationName,contactNo}})
            setbtndisable(true)  
           }
                 })
        .catch((err)=>{
         console.log('Error in send OTP',err)
         otpError()
        })
        },
      });
      const onValueChange = (value) => {
        formik.setFieldValue('contactno',value)
       };
    const {t}  = useTranslation()   
    return (
    <>
    <div className='MainWhole-wrapper-container'>   
    <Header/>
    {console.log('DataData',Data)}
     <div className="DetailsPage-Main-wrapper">
      {/* <h2> {t('cart_page_heading')}</h2> */}
      <h2> Enter your phone number</h2>
      <form onSubmit={formik.handleSubmit} style={{height:'100vh'}}>
      <div className="DetailsPage-content-wrapper">
          
      <div className="DetailsPage-content-wrapper-inner"> 
            {/* <InputLabel style={{fontFamily:"'Poppins', sans-serif"}} className='detailspage-content-label'> {t('cart_page_phone_number')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
            <div style={{ marginTop: '10px',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
          <div
            className="inline"
            style={{
              width: '100%',
              margin:'auto'
            }}
          >
            <PhoneInput
              style={{marginBottom: '10px', textAlign:'justify'}}
              country={'us'}
              specialLabel='Phone Number'
              enableSearch={true}
              autoFocus={true}
              value={formik.values.contactno}
              onChange={(value)=>onValueChange(value)}
              countryCodeEditable={false}
              margin="standard"
              name="contactno"
              label="Phone Number"
              // className="phone-number-verification"
              required
              containerStyle={{}}
              inputStyle={{
                border: '2px solid rgb(221 219 219)',
                borderRadius: '10px',
                width: '100%'
              }}
              dropdownStyle={{ }} 
              error={formik.touched.contactno &&
                Boolean(formik.errors.contactno)}
              helperText={formik.touched.contactno && formik.errors.contactno}
            />
        
          </div>
        </div>
            {formik.errors.contactno && formik.touched.contactno? <p style={{color:'#d32f2f',fontSize:'0.75rem',marginTop: '-2px',textAlign: 'left',paddingLeft: '28px'}}>Please Enter Phone Number</p>:null}
            </div>
          {/* <div className="DetailsPage-content-wrapper-inner">
            <InputLabel  style={{fontFamily:"'Poppins', sans-serif"}} className='detailspage-content-label'> {t('cart_page_delivery_location')} <sup style={{color:"red"}}> *</sup></InputLabel>
            <div className='DetailsPage_DeliveryLocation_Wrapper'> 
            <Accordion >
              <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
            <Typography style={{fontFamily:"'Poppins', sans-serif" , fontSize:'11px'}}>{t('cart_page_delivery_location')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{minHeight:'26vh'}}>
           {console.log('deliverylocation',deliverylocation)}
              <BottomNavigation
                showLabels
                name="deliverylocation"
                value={formik.values.deliverylocation}
                onChange={(event, newValue) => {
                    formik.setFieldValue('deliverylocation',newValue)
                 
                }}
                style={{ flexWrap:'wrap', position:'relative', display:'inline-block', textAlign:'start'}}
                error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                helperText={formik.touched.deliverylocation && formik.errors.deliverylocation} 
            >
               
                {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status ==="ACTIVE")
                  return  <BottomNavigationAction label={item.locationName} value={item.locationName} style={{padding:'9px 9px'}} />
                })}
             
                
               
            </BottomNavigation>
      
            </AccordionDetails>
         </Accordion>
       
            </div> 
          {(formik.touched.deliverylocation &&formik.errors.deliverylocation)? 
          <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> {t('Detail_page_please_select_delivery_location')}</p>:null }
            </div> */}
             <div className='Details_page_selected_deivery_location_wrapper'> 
                  <h4> Selected gate: <span> {locationName}</span></h4>
              </div>
      </div>
        <div className="Details-page-Nextbutton-wrapper"> 
        {btndisable ? null :  <button type="submit" style={{cursor:'pointer'}}> {t('Details_page_next_button')}</button> }
        </div>
        </form>   
     </div>
     <ToastContainer/>
   </div> 
    </>
  )
}

export default Details